import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`About`}</h2>
    <p>{`Hi, I'm Sebastian Rojo, Co-Founder at RisePal, ex-CTO former owner of Sapian, and founder of startup WunderComm. I'm part of Linux/Open Source revolutions. According to my ability and scope, I was an active player in my city's free software and Linux revolution. Now Linux and open source is state of the art in our technology World. For 13 years, I have created a team that believes in the freedom offered by new technologies, with which we have democratized simple solutions that empower hundreds of companies.`}</p>
    <p>{`I am passionate about evaluating new open source technologies. As a result, I can quickly implement, integrate, and interoperate nascent technologies to assess their disruptive capacity and challenge the technological status quo. `}</p>
    <p>{`After 13 years of applying active learning and learning strategies, I have become a generalist CTO with many initiatives, always thinking about Innovation using analytical thinking, Complex problem-solving, Creativity, and originality.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      