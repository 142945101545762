import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Projects`}</h2>
    <ProjectCard title="Slack to Matrix Migration" link="https://slack2matrix.sapian.cloud/?utm_source=arpagon_co&utm_medium=Starter" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
  Migrates Users, Channels and all the conversations from Slack and export to Matrix
  #Python #Matrix #Slack
    </ProjectCard>
    <ProjectCard title="JOE" link="https://joe.dialbox.cloud/?utm_source=arpagon_co&utm_medium=Starter" bg="linear-gradient(to right, #662D8C 0%, #ED1E79 100%)" mdxType="ProjectCard">
  J.A.R.V.I.S (Just A Rather Very Intelligent System) Online Edition
  Joe is a Voice Assistant designed to democratize this technology. How do we manage to democratize Voice Assistance technology? We believe that we can make this technology more accessible by allowing technologically obsolete communication systems to access the assistant.
  #SIP #AI #VoiceBot #NoCode
    </ProjectCard>
    <ProjectCard title="DoePhone" link="https://github.com/sapianco/DoePhone/?utm_source=arpagon_co&utm_medium=Starter" bg="linear-gradient(to right, #D585FF 0%, #00FFEE 100%)" mdxType="ProjectCard">
  DialBox Online Edition WebRTC Softphone will allow you to make and answer calls directly from a browser, no installation or upgrades are required.
  #WebRTC #Flutter #SIP
    </ProjectCard>
    <ProjectCard title="kzdroe" link="https://github.com/sapianco/kzdroe/?utm_source=arpagon_co&utm_medium=Starter" bg="linear-gradient(to right, #009245 0%, #FCEE21 100%)" mdxType="ProjectCard">
  DialBox Recordigns Online Edition for Kazoo 2600hz. Is a simple Python Flask for receiving a recording from doe.dialbox.cloud and create and archive by account on s3 buckets.
  #Qoutas #S3 #Kazoo #Pyhton #flask
    </ProjectCard>
    <ProjectCard title="whisper-large-v2-es" link="https://twitter.com/arpagon/status/1605248625856372737" bg="linear-gradient(to right, #D585FF 0%, #ED1E79 100%)" mdxType="ProjectCard"> This model is a fine-tuned version of openai/whisper-large-v2 on the mozilla-foundation/common_voice_11_0 es dataset. It achieves the following results on the evaluation set:
      <pre><code parentName="pre" {...{}}>{`Loss: 0.1702
Wer google/fleurs: 4.89
Wer mozilla-foundation/common_voice_11_0: 5.2882
`}</code></pre>
      <p>{`  #ML #Whisper #OpenAI #Pyhton #OpenSource`}</p>
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      